/**
 * @generated SignedSource<<c36fe71c15e0a9649eb98488d97e2e00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topicWidgetsQuery$data = {
  readonly topic: {
    readonly " $fragmentSpreads": FragmentRefs<"hooksTopic" | "topicAdsTopic" | "topicDescriptionTopic" | "topicMetadataDisplayTopic">;
  };
  readonly " $fragmentType": "topicWidgetsQuery";
};
export type topicWidgetsQuery$key = {
  readonly " $data"?: topicWidgetsQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"topicWidgetsQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "topicWidgetsQuery",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "hooksTopic",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "types",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Sponsor",
              "kind": "LinkedField",
              "name": "sponsor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "topicAdsTopic"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "topicDescriptionTopic"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "topicMetadataDisplayTopic"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c862bf5ee5d581ee053c791cbf6f5e88";

export default node;
