import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";

export const Container = styled.div`
  padding: 24px;

  border: 1px solid #bbbbbb;
  border-radius: 8px;

  background: #f6f6f6;

  ${props => props.theme.breakpoints.up("tablet")} {
    max-inline-size: 600px;
    margin-inline: auto;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 16px;

  margin-block-end: 16px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 130%;
`;

export const ImageIcon = styled(BaseImage)`
  inline-size: 48px;
  block-size: 48px;

  border: 1px solid #bbbbbb;
  border-radius: 50%;

  object-fit: cover;
  object-position: 50%;
`;

type DescriptionContainerProps = {
  $isShowFullProfile?: boolean;
};

export const DescriptionContainer = styled.div<DescriptionContainerProps>`
  position: relative;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: block;

    max-block-size: none;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 140%;

  a {
    display: initial;

    text-decoration: underline;
  }
`;

export const RawDescription = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0%;
  z-index: -1;

  font-size: 14px;
  line-height: 140%;

  visibility: hidden;
`;

export const ShowButton = styled.button`
  margin-block-start: 8px;

  color: #4585ff;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;

  background: none;

  cursor: pointer;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: none;
  }
`;

export const Divider = styled.div`
  margin-block: 16px 16px;
  border-block-start: 1px solid #bbbbbb;
`;

export const UpdatedDateContainer = styled.div`
  display: flex;
  gap: 4px;

  color: #666666;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 18.2px;
  line-height: 18.2px;
  text-align: start;
  text-transform: uppercase;
`;

export const UpdatedDateTitle = styled.div`
  font-weight: 400;
`;

export const UpdatedDateDescription = styled.div`
  font-weight: 700;
  font-size: 14px;
`;
