import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { useTopicType } from "scmp-app/components/topic/hooks";
import { formatInTimeZone } from "scmp-app/lib/utils";
import type { topicDescriptionTopic$key } from "scmp-app/queries/__generated__/topicDescriptionTopic.graphql";

import {
  Container,
  Description,
  DescriptionContainer,
  Divider,
  ImageIcon,
  Title,
  TitleContainer,
  UpdatedDateContainer,
  UpdatedDateDescription,
  UpdatedDateTitle,
} from "./styles";
import { TopicMetadataDisplay } from "./topic-metadata-display";

type Props = {
  className?: string;
  reference: topicDescriptionTopic$key;
};

export const TopicDescription: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const topic = useFragment(
    graphql`
      fragment topicDescriptionTopic on Topic {
        ...topicHeaderRelatedLinksTopic
        ...entityFollowButtonBase
        ...hooksTopic
        ...topicMetadataDisplayTopic
        mobileImage: image(type: MOBILE_APP_IMAGE) {
          alt
          title
          style(filter: { style: "300x300" }) {
            url
          }
        }
        name
        description {
          text
        }
        updatedDate
      }
    `,
    reference_,
  );

  const { currentType, hasSponsor } = useTopicType(topic);
  const isGeneralTopic = currentType === "general";
  const isShowImageIcon = !isGeneralTopic && !hasSponsor;

  if (!topic.description?.text) return null;

  return (
    <Container className={className}>
      <TitleContainer>
        {isShowImageIcon && topic.mobileImage?.style?.url && (
          <ImageIcon
            $aspectRatio="1"
            alt={topic.mobileImage.alt ?? topic.mobileImage.title ?? ""}
            lazyload={false}
            src={topic.mobileImage.style.url}
          />
        )}
        <Title>{topic.name}</Title>
      </TitleContainer>
      <DescriptionContainer>
        <Description>{topic.description.text}</Description>
        <TopicMetadataDisplay reference={topic} />
        {topic.updatedDate && (
          <>
            <Divider />
            <UpdatedDateContainer>
              <UpdatedDateTitle>last updated: </UpdatedDateTitle>
              <UpdatedDateDescription>
                {formatInTimeZone(topic.updatedDate, "d MMMM, yyyy")}
              </UpdatedDateDescription>
            </UpdatedDateContainer>
          </>
        )}
      </DescriptionContainer>
    </Container>
  );
};

TopicDescription.displayName = "TopicDescription";
