import type { NextSeoProps } from "next-seo";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { PageSeo } from "scmp-app/components/meta";
import { DefaultTitleTemplate } from "scmp-app/components/meta/consts";
import {
  useDeepLinkTags,
  useDynamicMetaTags,
  useKeywordMetaTags,
} from "scmp-app/components/meta/hooks";
import { useCanonicalUrl } from "scmp-app/lib/hooks";
import type { topicSeoTopic$key } from "scmp-app/queries/__generated__/topicSeoTopic.graphql";

type Props = {
  extraSeoOptions?: NextSeoProps;
  reference: topicSeoTopic$key;
};

export const TopicSeo: FunctionComponent<Props> = ({
  extraSeoOptions = {},
  reference: reference_,
}) => {
  const topic = useFragment(
    graphql`
      fragment topicSeoTopic on Topic {
        name
        description {
          text
        }
        urlAlias
        metaDescription
        ...hooksUseDeepLinkTagsBase
        ...hooksUseDynamicMetaTags
      }
    `,
    reference_,
  );

  const metaTags = useDynamicMetaTags(topic);
  const keywordTags = useKeywordMetaTags(metaTags.controlled.keywords ?? topic.name);
  const deepLinkTags = useDeepLinkTags(topic);

  let titleTemplate = DefaultTitleTemplate;
  const title = useMemo(
    () => metaTags.controlled.title ?? topic.name,
    [metaTags.controlled.title, topic.name],
  );
  if (metaTags.controlled.title) {
    titleTemplate = "%s";
  }

  const description =
    metaTags.controlled.description ??
    topic.metaDescription ??
    topic.description?.text ??
    undefined;

  const { canonicalUrl } = useCanonicalUrl(topic.urlAlias);

  return (
    <PageSeo
      {...extraSeoOptions}
      additionalMetaTags={[...keywordTags, ...deepLinkTags]}
      canonical={canonicalUrl}
      description={description}
      openGraph={{ description, title, url: canonicalUrl }}
      title={title}
      titleTemplate={titleTemplate}
    />
  );
};

TopicSeo.displayName = "TopicSeo";
