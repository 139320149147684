import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { AiArticleWidget } from "scmp-app/components/section/ai-article-widget";
import { useTopicType } from "scmp-app/components/topic/hooks";
import { TopicAds } from "scmp-app/components/topic/topic-ads";
import { TopicDescription } from "scmp-app/components/topic/topic-description";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";
import { AdditionalWidget, Details } from "scmp-app/pages/topic/styles";
import type { topicWidgetsQuery$key } from "scmp-app/queries/__generated__/topicWidgetsQuery.graphql";

type Props = {
  isShowAdditionalWidget: boolean;
  maxAdsNumber: number;
  reference: topicWidgetsQuery$key;
};

export const TopicWidgets: FunctionComponent<Props> = ({
  isShowAdditionalWidget,
  maxAdsNumber,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment topicWidgetsQuery on Query @argumentDefinitions(entityId: { type: "String!" }) {
        topic(filter: { entityId: $entityId }) {
          ...hooksTopic
          ...topicAdsTopic
          ...topicDescriptionTopic
          ...topicMetadataDisplayTopic
        }
      }
    `,
    reference,
  );

  const { hasSponsor } = useTopicType(data.topic);

  const renderDetails = (
    <Details>
      <TopicDescription reference={data.topic} />
    </Details>
  );

  const renderAdditionalWidget = isShowAdditionalWidget ? (
    <AdditionalWidget>
      <AiArticleWidget aiEngineDatatype={AiEngineDataType.HomeRecommendedForYou} />
    </AdditionalWidget>
  ) : null;

  const renderTopicAds = <TopicAds maxAdsNumber={maxAdsNumber} reference={data.topic} />;

  if (hasSponsor) {
    return (
      <>
        {renderDetails}
        {renderTopicAds}
      </>
    );
  }
  return (
    <>
      {renderDetails}
      {renderAdditionalWidget}
      {renderTopicAds}
    </>
  );
};

TopicWidgets.displayName = "TopicWidgets";
