import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Loading } from "scmp-app/components/common/loading";
import { TopicHeader } from "scmp-app/components/topic/topic-header";
import { TopContentsContainer } from "scmp-app/components/topic/topic-top-contents/styles";

type Props = {
  theme: Theme;
};

export const borderStyle = (props: Props) => css`
  border-block-start: 1px solid rgba(0, 0, 0, 0.2);

  padding-block-start: 20px;

  ${props.theme.breakpoints.up("homeDesktop")} {
    padding-block-start: 32px;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;

  inline-size: 100%;
`;

export const ContentContainer = styled.div`
  inline-size: 100%;
  max-inline-size: 1024px;
  margin-inline: auto;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: 1208px;
    padding-inline: 0;
  }
`;

const getGridLayoutByTopic = (topicSector: string) => {
  switch (topicSector) {
    case "sponsor":
      return css`
        grid:
          "hero-contents ads-1" min-content
          "hero-contents details" min-content
          "top-contents-1 details" min-content
          "top-contents-1 ." min-content
          "top-contents-2 ." min-content
          "top-contents-3 ." min-content
          "top-contents-3 ads-2" min-content
          "top-contents-4 ." min-content
          "subscription-message ." min-content
          "top-contents-5 ads-3" min-content
          "top-contents-6 ." min-content
          "top-contents-7 ads-4" min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
    default:
      return css`
        grid:
          "hero-contents ads-1" min-content
          "hero-contents details" min-content
          "top-contents-1 details" min-content
          "top-contents-2 additional-widget" min-content
          "top-contents-3 additional-widget" min-content
          "top-contents-3 ads-2" 1fr
          "top-contents-4 ads-2" min-content
          "subscription-message ." min-content
          "top-contents-5 ads-3" min-content
          "top-contents-6 ." min-content
          "top-contents-7 ads-4" min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
  }
};

type ContentWrapperProps = {
  $topicSector?: string;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: grid;
  grid:
    "hero-contents" min-content
    "ads-1" min-content
    "details" min-content
    "top-contents-1" min-content
    "top-contents-2" min-content
    "additional-widget" min-content
    "ads-2" min-content
    "top-contents-3" min-content
    "top-contents-4" min-content
    "subscription-message" min-content
    "top-contents-5" min-content
    "ads-3" min-content
    "top-contents-6" min-content
    "top-contents-7" min-content
    "ads-4" min-content
    / minmax(0, 1fr);

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    ${props => getGridLayoutByTopic(props.$topicSector ?? "")};
    column-gap: 40px;
  }
`;

export const Header = styled(TopicHeader)`
  margin-block: 20px 20px;

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block: 32px 32px;
  }
`;

export const HeroContents = styled.div`
  grid-area: hero-contents;

  margin-block-end: 32px;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block-end: 24px;

    border-block-end: 1px solid rgba(0, 0, 0, 0.2);
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-end: 32px;
    padding-block-end: 0;
    border-block-end: none;
  }
`;

export const TopContents1 = styled.div`
  grid-area: top-contents-1;

  ${borderStyle};
  ${props => props.theme.breakpoints.up("tablet")} {
    border-block-start: none;

    padding-block-start: 0;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-block-start: 32px;
    border-block-start: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

export const TopContents2 = styled.div`
  grid-area: top-contents-2;

  ${borderStyle}

  ${TopContentsContainer} {
    padding-block-end: 32px;
  }
`;

export const TopContents3 = styled.div`
  grid-area: top-contents-3;

  ${borderStyle}

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    border-block-start: 1px solid rgba(0, 0, 0, 0.2);

    padding-block-start: 32px;
  }
`;

export const TopContents4 = styled.div`
  grid-area: top-contents-4;

  ${borderStyle}

  ${TopContentsContainer} {
    padding-block-end: 32px;
  }
`;

export const TopContents5 = styled.div`
  grid-area: top-contents-5;

  ${borderStyle}
`;

export const TopContents6 = styled.div`
  grid-area: top-contents-6;

  ${borderStyle}
`;

export const TopContents7 = styled.div`
  grid-area: top-contents-7;

  ${borderStyle}
`;

export const SubscriptionMessage = styled.div`
  grid-area: subscription-message;

  margin-block-end: 32px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block: 16px 48px;
  }
`;

export const Details = styled.div`
  grid-area: details;

  margin-block-end: 32px;
`;

export const AdditionalWidget = styled.div`
  grid-area: additional-widget;

  margin-block-end: 32px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-end: 40px;
  }
`;

export const Ads1 = styled.div`
  grid-area: ads-1;

  margin-block-end: 32px;
`;

export const Ads2 = styled.div`
  grid-area: ads-2;

  max-block-size: 630px;
  margin-block-end: 32px;
`;

export const Ads3 = styled.div`
  grid-area: ads-3;

  margin-block-end: 32px;
`;

export const Ads4 = styled.div`
  grid-area: ads-4;

  margin-block-end: 32px;
`;

export const RestContentWrapper = styled.div``;

export const StyledLoading = styled(Loading)`
  padding-block: 60px;
`;
