import styled from "@emotion/styled";

import { Figure } from "scmp-app/components/content/content-cover-image/styles";
import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import { Headline } from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { ContentItemSectionTopLeadPrimary } from "scmp-app/components/content/content-item-render/variants/section-top-lead-primary";
import {
  Headline as LeadHeadline,
  LeadingImage,
  SubHeadline as LeadSubHeadline,
  Summary as LeadSummary,
} from "scmp-app/components/content/content-item-render/variants/section-top-lead-primary/styles";
import { SectionLink } from "scmp-app/components/content/content-sections/styles";
import {
  HomeSponsorTagContainer,
  HomeSponsorTagWrapper,
} from "scmp-app/components/sponsor-tag/styles";
export const HeroContentsContainer = styled.div`
  ${LeadingImage} {
    ${Figure} {
      aspect-ratio: 3/2;

      ${props => props.theme.breakpoints.up("tablet")} {
        aspect-ratio: 250/99;
      }
    }
  }

  ${LeadHeadline} {
    font-size: 18px;
    line-height: 25.2px;
    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 22px;
      line-height: 30.8px;
    }
    ${props => props.theme.breakpoints.up("homeDesktop")} {
      font-size: 28px;
      line-height: 39.2px;
    }
  }

  ${LeadSubHeadline} {
    display: none;
    ${props => props.theme.breakpoints.up("tablet")} {
      display: block;

      font-size: 16px;
      line-height: 140%;
    }
  }

  ${LeadSummary} {
    ${props => props.theme.breakpoints.up("tablet")} {
      display: none;
    }
  }

  ${SectionLink} {
    color: #001246;
    font-size: 15px;
    line-height: 18px;
  }
`;

export const StyledContentItemSectionTopLeadPrimary = styled(ContentItemSectionTopLeadPrimary)`
  font-size: 40px;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "leading-image leading-image" min-content
      "primary rest" min-content
      / 1fr calc((100% - 32px) / 3);
  }

  ${HomeSponsorTagContainer} {
    max-inline-size: 240px;
  }

  ${HomeSponsorTagWrapper} {
    inline-size: 100%;
  }
`;

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)`
  display: flex;
  flex-direction: column;
`;

export const HeroRest = styled.div`
  ${StyledContentItemHomeSecondary} {
    &:not(:last-child) {
      margin-block-end: 20px;
      padding-block-end: 20px;
      border-block-end: 1px solid rgba(0, 0, 0, 0.1);
    }

    ${Headline} {
      font-size: 15px;
      line-height: 21px;
    }
  }
`;
