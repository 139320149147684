import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { topicMetadataDisplayTopic$key } from "scmp-app/queries/__generated__/topicMetadataDisplayTopic.graphql";

import {
  Container,
  Divider,
  ExtraMetadataContainer,
  ExtraMetadataDescription,
  ExtraMetadataItem,
  ExtraMetadataTitle,
  StyledBaseLink,
} from "./styles";

type Props = {
  className?: string;
  reference: topicMetadataDisplayTopic$key;
};

export const TopicMetadataDisplay: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const topic = useFragment(
    graphql`
      fragment topicMetadataDisplayTopic on Topic {
        ...hooksTopic
        extraMetadata {
          key
          value
          topic {
            urlAlias
          }
        }
      }
    `,
    reference_,
  );

  return (
    <Container className={className}>
      {topic.extraMetadata && topic.extraMetadata.length > 0 && (
        <>
          <Divider />
          <ExtraMetadataContainer>
            {topic.extraMetadata.map(
              (item, index) =>
                item?.key &&
                item?.value && (
                  <ExtraMetadataItem key={index}>
                    <ExtraMetadataTitle>{item.key}</ExtraMetadataTitle>
                    <ExtraMetadataDescription>
                      {item.value.split(";").map((line, index_) => (
                        <span key={index_}>
                          {index_ > 0 && <br />}
                          {item.topic?.urlAlias ? (
                            <StyledBaseLink pathname={item.topic.urlAlias}>{line}</StyledBaseLink>
                          ) : (
                            line
                          )}
                        </span>
                      ))}
                    </ExtraMetadataDescription>
                  </ExtraMetadataItem>
                ),
            )}
          </ExtraMetadataContainer>
        </>
      )}
    </Container>
  );
};

TopicMetadataDisplay.displayName = "TopicMetadataDisplay";
