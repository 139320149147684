import styled from "@emotion/styled";

import { TopContentsContainer } from "scmp-app/components/topic/topic-top-contents/styles";
import { borderStyle } from "scmp-app/pages/topic/styles";

export const TopContentsRestContainer = styled.div`
  display: grid;
  grid:
    "rest-contents" min-content
    "rest-ads" min-content
    / minmax(0, 1fr);

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    grid:
      "rest-contents rest-ads" min-content
      / minmax(0, 1fr) minmax(0, 300px);

    column-gap: 40px;
  }
`;

export const TopContentsRestContents = styled(TopContentsContainer)`
  grid-area: rest-contents;

  ${borderStyle}
`;

export const AdsRest = styled.div`
  grid-area: rest-ads;

  margin-block-end: 32px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-end: 0;
  }
`;
