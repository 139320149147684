/* eslint-disable relay/unused-fields */
import { scmpPlus } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import type { PageProps } from "next";
import { useEffect, useMemo } from "react";
import { graphql, usePaginationFragment, usePreloadedQuery } from "react-relay";
import type { RelayComponent } from "relay-nextjs";
import { withRelay } from "relay-nextjs";

import { useTopBannerAdSlot } from "scmp-app/components/advertisement/ad-slots/top-banner-ad-slot/hooks";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { AppFooter } from "scmp-app/components/app-footer";
import { FallbackLoading } from "scmp-app/components/fallback-loading";
import { useDynamicMetaTags } from "scmp-app/components/meta/hooks";
import { SubscriptionWidget } from "scmp-app/components/subscription-widget";
import { useTopicType } from "scmp-app/components/topic/hooks";
import { TopicHeroContents } from "scmp-app/components/topic/topic-hero-contents";
import { TopicRestContents } from "scmp-app/components/topic/topic-rest-contents";
import { TopicSeo } from "scmp-app/components/topic/topic-seo";
import { TopicTopContents } from "scmp-app/components/topic/topic-top-contents";
import { TopicWidgets } from "scmp-app/components/topic/topic-widgets";
import { trackingAtom } from "scmp-app/components/tracking";
import { data as appData } from "scmp-app/data";
import { useInfiniteScrollTriggerReference } from "scmp-app/lib/hooks";
import { createClientEnvironment } from "scmp-app/lib/relay/environment.client";
import { parseQueryString } from "scmp-app/lib/utils";
import type { topicPageQuery } from "scmp-app/queries/__generated__/topicPageQuery.graphql";
import type { topicTopicPageTopic$key } from "scmp-app/queries/__generated__/topicTopicPageTopic.graphql";

import { parseList } from "./helper";
import {
  Container,
  ContentContainer,
  ContentWrapper,
  Header,
  HeroContents,
  RestContentWrapper,
  StyledLoading,
  SubscriptionMessage,
  TopContents1,
  TopContents2,
  TopContents3,
  TopContents4,
  TopContents5,
  TopContents6,
  TopContents7,
} from "./styles";

type TopStoresTypes = typeof TopContents1;

const topContentsComponentMap: Record<string, TopStoresTypes> = {
  TopContents1,
  TopContents2,
  TopContents3,
  TopContents4,
  TopContents5,
  TopContents6,
  TopContents7,
};

const query = graphql`
  query topicPageQuery(
    $after: String
    $applicationId: String!
    $count: Int = 28
    $entityId: String!
    $scmpPlusPaywallTypeIds: [String]
    $superTopicQueueName: String!
  ) {
    ...topicWidgetsQuery @arguments(entityId: $entityId)
    topic(filter: { entityId: $entityId }) {
      entityId
      entityUuid
      advertZone(version: 2)
      sponsor {
        name
      }
      name
      urlAlias
      ...hooksTopic
      ...hooksUseDynamicMetaTags
      ...topicAdsTopic
      ...topicHeaderTopic
      ...topicRestContentsTopic
      ...topicSeoTopic
      ...topicTopContentsTopic
      ...topicTopicPageTopic
        @arguments(
          applicationId: $applicationId
          after: $after
          count: $count
          scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
        )
    }
    superTopicQueue: queue(filter: { name: $superTopicQueueName }) {
      items(first: 10) {
        edges {
          node {
            ... on Content {
              entityUuid
              ...homeSecondaryContentItemContent
              ...sectionTopLeadPrimaryContentItemContent
              ...topStoriesItemContent
            }
          }
        }
      }
    }
  }
`;

const TopicPage: RelayComponent<unknown, topicPageQuery> = ({ preloadedQuery }) => {
  const data = usePreloadedQuery(query, preloadedQuery);

  const {
    data: contentList,
    hasNext: hasNextPage,
    isLoadingNext: isLoading,
    loadNext,
  } = usePaginationFragment<topicPageQuery, topicTopicPageTopic$key>(
    graphql`
      fragment topicTopicPageTopic on Topic
      @argumentDefinitions(
        after: { type: "String" }
        count: { type: "Int", defaultValue: 28 }
        applicationId: { type: "String" }
        scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }
      )
      @refetchable(queryName: "topicTopicPagePaginationQuery") {
        contents(
          after: $after
          exclude: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          first: $count
          filter: { applicationIds: [$applicationId] }
          orderBy: { field: PUBLISHED_DATE, direction: DESC }
        ) @connection(key: "topicTopicPage__author_contents") {
          edges {
            node {
              ... on Content {
                entityUuid
                ...homeSecondaryContentItemContent
                ...sectionTopLeadPrimaryContentItemContent
                ...topStoriesItemContent
              }
            }
          }
        }
      }
    `,
    data.topic,
  );

  const { hasSponsor = false, isSuperTopic = false } = useTopicType(data.topic);

  useTopBannerAdSlot({
    desktop: {
      adUnit: "d_banner1",
      sizes: [[970, 250], [728, 90], [970, 90], "fluid"],
      targeting: {
        paid: TargetingPaidType.Free,
        sctid: data.topic.entityId,
      },
      zone: data.topic.advertZone,
    },
    mobile: {
      adUnit: "m_banner3",
      targeting: {
        paid: TargetingPaidType.Free,
        sctid: data.topic.entityId,
      },
      zone: data.topic.advertZone,
    },
  });

  const isShowFooter = useMemo(
    () =>
      // this is to prevent eslint throw warning relay/unused-fields
      !contentList.contents?.edges || !hasNextPage,
    [contentList.contents?.edges, hasNextPage],
  );

  // Prevent send page view in SEO component as Plus will use same SEO component
  const metaTags = useDynamicMetaTags(data.topic);
  const { trackPageView } = useAtomValue(trackingAtom);
  useEffect(() => {
    const { entityId, entityUuid, name, sponsor, urlAlias } = data.topic;
    trackPageView?.({
      contentType: "Topic",
      entityId,
      entityUuid,
      page: "Topic",
      pageTitle: metaTags?.controlled.title ?? name,
      path: urlAlias,
      sponsor: sponsor?.name,
      topicID: entityId,
      topics: name,
    });
  }, [trackPageView, metaTags?.controlled.title, data.topic]);

  const { infiniteScrollTriggerReference } = useInfiniteScrollTriggerReference({
    hasNextPage,
    isLoading,
    onLoadMore: () => loadNext(11),
  });

  const {
    heroContentsGroup,
    isShowAdditionalWidget,
    isShowSubscriptionMessage,
    maxAdsNumber,
    restContentsComponents,
    topContentsComponents,
  } = parseList(contentList, hasNextPage, hasSponsor, data, isSuperTopic);

  return (
    <>
      <TopicSeo reference={data.topic} />
      <Container>
        <ContentContainer>
          <Header reference={data.topic} />
          <ContentWrapper>
            <HeroContents>
              {heroContentsGroup && (
                <TopicHeroContents heroContentsGroup={heroContentsGroup} onClick={() => {}} />
              )}
            </HeroContents>
            {topContentsComponents?.map((topContents, index) => {
              const ComponentType = topContentsComponentMap[topContents.type];
              return (
                <ComponentType key={topContents.type + index}>
                  <TopicTopContents
                    index={index}
                    reference={data.topic}
                    topContents={topContents}
                  />
                </ComponentType>
              );
            })}
            {isShowSubscriptionMessage && (
              <SubscriptionMessage>
                <SubscriptionWidget />
              </SubscriptionMessage>
            )}
            <TopicWidgets
              isShowAdditionalWidget={isShowAdditionalWidget}
              maxAdsNumber={maxAdsNumber}
              reference={data}
            />
          </ContentWrapper>
          {restContentsComponents && (
            <RestContentWrapper>
              {restContentsComponents.map((restContents, index) => (
                <TopicRestContents
                  index={index}
                  key={index}
                  reference={data.topic}
                  restContents={restContents}
                />
              ))}
            </RestContentWrapper>
          )}
          {hasNextPage && (
            <StyledLoading ref={element => infiniteScrollTriggerReference(element)} />
          )}
        </ContentContainer>
        {isShowFooter && <AppFooter variant="topic" />}
      </Container>
    </>
  );
};

TopicPage.displayName = "TopicPage";

const pageProps: PageProps = {
  appBarConfiguration: {
    adSlotVariants: {
      large: {
        height: 250,
        padding: 20,
      },
      small: {
        height: 100,
        padding: 10,
      },
    },
    hasDesktopAd: true,
    hasMobileAd: true,
    variant: "scmp/home-slim",
  },
  contentConfiguration: {
    responsivePadding: {
      desktopUp: 12,
      tabletUp: 0,
    },
  },
  pageConfiguration: {
    responsivePadding: {
      tabletUp: 24,
    },
  },
};

export default withRelay(TopicPage, query, {
  clientSideProps: () => pageProps,
  createClientEnvironment: () => createClientEnvironment(),
  createServerEnvironment: async context => {
    const { createServerEnvironment } = await import("scmp-app/lib/relay/environment.server");
    return createServerEnvironment(context.req?.cookies);
  },
  fallback: <FallbackLoading />,
  serverSideProps: () => Promise.resolve(pageProps),
  variablesFromContext(context) {
    const entityId = parseQueryString(context.query.entityId) ?? "";
    return {
      applicationId: appData.application.scmp.entityUuid,
      entityId,
      scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
      superTopicQueueName: `topic_super_topic_${entityId}`,
    };
  },
});
